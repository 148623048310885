#wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 90%;
   margin: auto;
   margin-bottom: 35px;
}

.content {
   width: 100%;
   margin: 0 5%;
   margin-top: 4em;
   transition: 0.3s ease;

   @media screen and (max-width: 512px) {
      margin: 0 4%;
      margin-top: 5%;
   }
}

/* Tabs menu */

.tabs {
   margin: 0;
   background-image: linear-gradient(135deg,  0%, 100%);
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 60px;
   border-radius: 2px 2px 0px 0px;

   @media screen and (max-width: 512px) {
      height: 40px;
   }
}

/* Tab Links */

.tablinks {
   /*background: transparent;*/
   background-image: linear-gradient(270deg, transparent 40%, rgba(252, 246, 246, 0.2) 100%);
   background-color: rgb(98,168,248,0.8);
   border: none;
   outline: none;
   cursor: pointer;
   width: 25%; /*change depending on the number of tabs*/
   height: 60px;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 16px;
   font-family: "IBM Plex Sans", sans-serif;
   overflow: hidden;
   transition: 0.3s ease;

   @media screen and (max-width: 512px) {
      height: 40px;
      font-size: 8px;
      padding: 9px;
   }
}

.tablinks:before /* Gradient */ {
   background-image: linear-gradient(135deg,  0%,  100%);
   content: "";
   width: 100%;
   height: 0px;
   position: absolute;
   top: 0;
   left: 0;
   transition: 0.3s ease-in-out;
   z-index: 2;
}


/*tab buttons hover*/

.tablinks:hover::before {
   height: 100%;
   z-index: 2;
   bottom: 0;
   
   @media screen and (max-width: 512px) {
      height: 0;
   }
}


/* Tab active */

.tablinks.active {
   background-color: rgb(98,168,248,1);
   font-weight: 1200;
   z-index: 0;
   border-right: 0px;
   border-left: 0px;
   height: 67px;
   bottom: 0px;
   overflow: hidden;
   
   @media screen and (max-width: 512px) {
      height: 50px;
   }
}
.tablinks.active p{
   font-weight: 700;
}
.tablinks.active:before /* Gradient */ {
   content: "";
   width: 100%;
   height: 5px;
   top: 0;
   left: 0;
}

/* Tabs text */

.tablinks.active p,
.tablinks.active:hover p {
   opacity: 1;
   background: -webkit-linear-gradient(135deg,  45%,  100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: rgb(5, 5, 87);
}

.tablinks p {
   opacity: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2;
   transition: 0.5s ease;
   padding: 0;
   margin: 0;
   color: rgb(255, 255, 255);
   backface-visibility: hidden;
   font-weight: 500;
}

.tablinks:hover p {
   color: white;
   opacity: 1;
   font-weight: 600;
   
   @media screen and (max-width: 512px) {
      color: rgb(22, 2, 87);
      opacity: 0.6;
   }
}


/* Tabs text bigger */

.tablinks p:before {
   content: attr(data-title);
   position: absolute;
   height: auto;
   width: auto;
   color: rgb(223, 141, 141);
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0.1;
   font-size: 40px;
   transition: 1s ease-out;
   z-index: -1;
   font-weight: 600;
   top: 110%;
   
   @media screen and (max-width: 512px) {
      display: none;
   }
}

.tablinks:hover p:before {
   opacity: 0.1;
   font-size: 40px;
   top: -80%;
}


/* tab content */

.wrapper_tabcontent {
   background-color: rgb(255,255,255);
   margin-top: 0px;
   z-index: -3;
   position: relative;
   opacity: 1;
   padding: 20px 20px;
   overflow: hidden;
   transition: all 1s ease;
   top: 0;
}

.tabcontent {
   display: none;
   
}

@keyframes tabEffect {
   from {
      top: -40px;
   }
   to {
      top: 0px;
   }
}

.tabcontent.active {

   transition: all 1s ease;
   display: block;
}

/* Tab content line */
.wrapper_tabcontent:after {
   content: "";
   height: 5px;
   width: 100%;
   position: absolute;
   background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
   left: 0;
   bottom: 0;
   z-index: -2;
   transition: all 1s ease;
}

/* Title */
.tabcontent h3 {
   font-size: 40px;
   top: 75px;
   transform: rotate(90deg);
   position: absolute;
   left: -90px;
   opacity: 0.1;
   width: 200px;
   height: 60px;
   background: -webkit-linear-gradient(135deg, #667eea 0%, #764ba2 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   padding: 0;
   animation: city 1s ease;

   @media screen and (max-width: 512px) {
      top: 65px;
   }
}

@keyframes city {
   from {
      left: -150px;
   }
   to {
      left: -90px;
   }
}

/* Text*/
.tabcontent p {
   color: rgb(3, 10, 110);
   margin: 0;
   padding: 0;
   line-height: 28px;
   font-weight: 100;
   transition: all 1s ease;
   animation: fadeEffect 0.6s ease;
   width: 100%;
   font-size: 16px;
   font-family: "IBM Plex Sans", sans-serif;

   @media screen and (max-width: 512px) {
      font-size: 14px;
      line-height: 26px;
   }
}

@keyframes fadeEffect {
   from {
      opacity: 0;
      margin-left: 30px;
   }
   to {
      opacity: 1;
      margin-left: 0;
   }
}
.wrapper_tabcontent .tabcontent .display{
   display: flex;
   flex-direction: row;
   align-items:center;
   justify-content: space-between;
   margin: auto;
   @media screen and (max-width: 512px) {
      flex-direction: column;
   }
}