@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-image: url('./imagProps/5570834.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

