.mems-flex{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    @media screen and (max-width:769px){
        flex-direction: column;
      }
}
.testhead{
    color: aliceblue;
    font-weight: 600;
    margin-top: 20px;
}

.hello1{
    margin: 20px;
}
.hello1 h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 35px;
    color: #1c064f;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    text-align: left;
    padding-top: 15px;
}
  
  .two1 h1 {
    text-transform: capitalize;
    line-height: 1; /* Add this to adjust the line height for the heading */
  }
  
  .two1 h1 span {
    position: absolute;
    left: 0;
    top: 100%; /* Move it just below the "Vision" text */
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: rgb(39, 3, 96);
    padding-left: 0.25em;
    padding-top: 0.25em; /* Add some space between the lines */
  }
  
  .two1 h1:before {
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 120px;
    height: 4px;
    content: "";
    background-color: #510d31;
  }
  .hello1 p{
    font-size: 1em;
    font-style: italic;
    padding: 15px 25px 15px 25px;
    color: #290f65;

  }
  .two1{
    padding-left: 25px;
  }

  .button-15 {
    margin-top: 10px;
    margin: auto;
    background-image: linear-gradient(#42A1EC, #0070C9);
    border: 1px solid #0077CC;
    border-radius: 4px;
    box-sizing: border-box;
    color: #020121;
    cursor: pointer;
    direction: ltr;
    display: block;
    font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -.022em;
    line-height: 1.47059;
    min-width: 30px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    width: 50%;
  }
 
  .button-15:hover{
    color:white;
  }