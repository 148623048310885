
.body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 16px 16px;
    margin: auto;
    gap: 2em;
    padding-bottom: 40px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}