body{
    overflow-x: hidden;

}
::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(1, 1, 56);
   
}

::-webkit-scrollbar-track {
    background-color: rgb(1, 1, 56);
    margin: 10px;
    margin-top: 9vh;
    border-radius: 10px;
    border: 1px solid #62a8f8;
}

::-webkit-scrollbar-thumb {
    background-color: #7db5f7;   
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #62a8f8;
    /* opacity: 1; */
}