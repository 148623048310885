.wrapper
{
  position: relative;
} 

  .wrapper h2{
    font-size: 2em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    
    
  }
  .news {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap:10px;
    padding:20px;
    @media screen and (max-width:769px){
      flex-direction: column;
    }
  }
  
  .news .article {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: auto;
    margin: 20px;
    @media screen and (max-width:769px){
        margin-inline: auto;
        
    }
  }
  
  .news .article img {
    width: 100%;
    min-height: 100%;
    transition: 0.2s;
    box-shadow: 5px 5px 10px rgba(5, 0, 21, 0.5);
    border: 8px solid rgb(26, 150, 212);
  }
  
  .news .article figcaption {
    position: absolute;
    font-size: 0.8em;
    text-shadow: 0 1px 0 rgba(51, 51, 51, 0.3);
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    background: rgba(6, 18, 53, 0.8);
    opacity: 0;
    transform: scale(1.15);
    transition: 0.2s;
  }
  
  .news .article:hover,
  .news .article:focus,
  .news .article:active {
    transform: translateY(-3px) scale(1.05);
  }
  
  .news .article:hover img,
  .news .article:focus img,
  .news .article:active img {
    filter: blur(3px);
    transform: scale(0.97);
  }
  
  .news .article:hover figcaption,
  .news .article:focus figcaption,
  .news .article:active figcaption {
    opacity: 1;
    transform: none;
  }
  
  .news .article h3 {
    color: #3792e3;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: bold;
  }


/* CSS */
.button-151 {
  margin-top: 10px;
  background-image: linear-gradient(#42A1EC, #0070C9);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}



.article {
    --color: rgb(205, 244, 248); /* the border color */
    --border: 2px;   /* the border thickness */
    --offset: 6px;  /* control the offset */
    --gap: 5px;      /* the gap on hover */
  
    /* Define a linear gradient for the border */
    border-image: linear-gradient(var(--color), var(--color)) var(--border);
    border-image-slice: 1;
    border-image-width: var(--border);
    border-image-outset: var(--offset);
  
    --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)), var(--color) 0;
    --_o: calc(3 * var(--offset));
    padding: calc(var(--gap) + var(--border))
      calc(var(--gap) + var(--border) + var(--offset))
      calc(var(--gap) + var(--border) + var(--offset))
      calc(var(--gap) + var(--border));
    background:
      linear-gradient(var(--_c)) var(--_o) var(--_o),
      linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
    background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
    background-repeat: no-repeat;
    filter: grayscale(.4);
    transition: .5s;
    cursor: pointer;
  }
  
  .article :hover {
    background-position: 0px 0px;
    background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
    filter: grayscale(0);
  }
  
  .link{
    font-size:1.2em;
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif;
    position: absolute; /* Position the child div absolutely within the parent */
    bottom: 0px; /* Align the bottom of the child div to the bottom of the parent */
    right: 45px; /* Align the right side of the child div to the right side of the parent */ 
  }
  .linkstyle{
    display: flex;
    align-items: center;
    text-decoration: underline;

  }
  .icon-style{
    margin-left: 8px;
  }