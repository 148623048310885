.counterflex {
  display: flex;
  border: 1px solid black;
  color: aliceblue;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  background-color: transparent;
}

.Counternumbs {
  font-size: calc(2.5rem + 1.3vw);
  text-align: center;
}

.months,
.membsp,
.events {
  text-align: center;

}
