.modal {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); 
  margin-top: 10vh;
}

.modal-overlay {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.modal-body a {
  position: absolute;
  display: inline;
  color: black;
  text-decoration: none;
  line-height: 36px;
  font-size: 30px;
  font-weight: lighter;
  background: #fff;
  border-radius: 5px;
  height: 40px; width: 40px;
  text-align: center;
}
.modal-body .modal-close {
  right: 0; 
  top: 0;
  border-radius: 0 0 0 5px;
}
.modal-body .modal-next,
.modal-body .modal-prev {
  right: 0; 
  top: calc(40%);
  border-radius: 5px 0 0 5px;
  height: 50px;
  line-height: 40px;
  font-size: 60px;
}
.modal-body .modal-prev {
  left: 0;
  right: auto;
  border-radius: 0 5px 5px 0;
}
.modal-body img{
  height: 400px;
  margin: auto;
  margin-top: 55px;
}
.modal-body{
  height: 100vh;
  width: 100vw;
}


.gallery-grid img{
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  cursor: pointer;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.hover-text {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
}

.image-wrapper:hover .hover-text {
  opacity: 1;
}
.hover-text p{
  font-size: 0.9em;
  margin: 10px;
}

.modal-content {
  display: inline-block; /* Make the content element inline-block */
  text-align: center; /* Center its content horizontally */
  width: 100%; /* Set the width to 100% to match the image's width */
}

.image-description {
  margin-top: 10px; /* Add some top margin to separate the description from the image */
  color: white;
}