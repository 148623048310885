*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.dropdown{
    display: inline-block;
    margin: 1vh auto;
    position: relative;
    user-select: none;
    border-radius: 10px;
    z-index:200;
}
.dropdown .dropdown-btn{
    padding: 10px 15px;
    background-color: rgb(3, 77, 142) ;
    box-shadow: 0 10px 9px rgba(0,0,0,0.35);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 0 15px 15px 0;
    margin: auto;
    background: linear-gradient(to left, rgba(103, 154, 171), rgba(3, 77, 142));
    max-height: 70px;
}

.dropdown .dropdown-content{
    position: absolute;
    left:0;
    top: 110%;
    padding: 15px;
    background-color: rgb(2, 9, 111);
    font-weight: 500;
    width: 100%;
    border-radius: 0 0 14px 14px;
    box-shadow: 0 10px 9px rgba(0,0,0,0.35);
}
.dropdown .dropdown-content .dropdown-item{
    padding:10px 0;
    cursor: pointer;
    transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item a{
    width: 100%;
    height: 100%;
    display: block;
}
.dropdown .dropdown-content .dropdown-item:hover{
    background-color: rgba(177, 181, 249,0.5) ;

}